<template>
  <div />
</template>

<script>
import axiosLoggingClient from '../plugins/axios';

export default {
  data: () => ({
    audits: [],
    metadata: {},
    bigqueryStats: {},
  }),
  computed: {
  },
  mounted() {
  },
  methods: {
    searchAudits(params) {
      axiosLoggingClient
        .get('/v3/logs', { params })
        .then((response) => {
          this.audits = response.data.audits;
          // eslint-disable-next-line camelcase
          const { bigquery_stats, ...restMetadata } = response.data?.metadata || {};
          // eslint-disable-next-line camelcase
          this.bigqueryStats = bigquery_stats;
          this.metadata = restMetadata;
        })
        .catch((error) => {
          console.error(error);
          this.$noty.error('Error fetching audits');
        });
    },
  },
};
</script>

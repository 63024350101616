import Vue from 'vue';
import VueNoty from 'vuejs-noty';
import 'vuejs-noty/dist/vuejs-noty.css';
import 'bootstrap';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from './plugins/axios';
import 'vuetify/dist/vuetify.min.css';
import './plugins/handlers/error';
import ACLModule from './acl-module';

Vue.prototype.$http = axios;

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueNoty, {
  timeout: 3000,
  progressBar: true,
  layout: 'bottomRight',
});

ACLModule.requestUserAccess().catch(() => {
  ACLModule.redirectToSignIn();
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount('#app');
